const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
  },

  formFields: {

    isApproved: {
      label: "Is Approved",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      isAdd: false,
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },
    
    
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
