const ModuleConfig = {
  primaryKey: "_id",

  formDefaultValue: {
    name: "",
    filename: "",
  },

  formFields: {
    store: {
      label: "Select Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
    },

    filename: {
      label: "File Name",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
   
    size: {
      label: "File Size",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select size",
      options: [
        {
          label: "Large",
          value: "large",
        },
        {
          label: "Small",
          value: "small",
        },
      ],
    },

    category: {
      label: "File Size",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select category",
      options: [
        {
          label: "Citylife",
          value: "Citylife",
        },
        {
          label: "Flags",
          value: "flags",
        },
        {
          label: "Free items",
          value: "freeitems",
        },
        {
          label: "Nature",
          value: "nature",
        },
      ],
    },

    approve: {
      label: "approve",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
