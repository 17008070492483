import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getImageListAsync, ImageState } from "../ImageSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { ImageDetailInterface } from "../models/ImageDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteImage } from "../ImageAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Formik } from "formik";
import { json } from "stream/consumers";
import AxiosInstance from "../../../utils/AxiosInstance";
import Config from "../../../utils/config";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

/**
 * Imageing list page
 */
const ImageList = () => {
  const { images, status, totalRecord } = useAppSelector(ImageState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const [deleteItem, setDeleteItem] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [confirmApprove, setconfirmApprove] = useState({
    id: "",
    isApproved: null,
  });

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getImageListAsync(filter));
  };

  const removeImage = async () => {
    const res = await deleteImage(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "file_path",
      text: "Thumbnail",
      classes: "col-1",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="">
          <img
            className="thumbnail"
            src={Config.filePath + row.file_path}
            alt={row.file_path}
          />
        </div>
      ),
    },
    {
      dataField: "owner",
      text: "Owner",
      formatter: (cellContent: any, row: any) => {
        if (row?.user[0]) {
          return row?.user[0]?.username;
        }
        return "";
      },
    },
    {
      dataField: "isApproved",
      text: "Is Approved",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="form-check form-switch d-flex justify-content-center">
          <input
            id="flexSwitchCheckDefault"
            type="checkbox"
            name="status"
            value={row.isApproved}
            onChange={(e) => {
              setconfirmApprove({ id: row._id, isApproved: e.target.checked });
            }}
            className="form-check-input"
            defaultChecked={row.isApproved}
          />
        </div>
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      classes: "col-2",
      formatter: (cellfileSize: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-trash-2 text-danger fw-bold"
            onClick={() => {
              setDeleteItem(row._id);
            }}
          ></i>
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/image/update/${row._id}`);
            }}
          ></i>
          <i
            onClick={() => {
              setImageUrl(row.file_path);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  const updateStatus = async (id, status) => {
    const res = await AxiosInstance.post("/images/" + id, {
      isApproved: status,
    });
    if (res.data.status) {
      setconfirmApprove({ id: "", isApproved: null });
      toast.success("Image Approved!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getPageData();
    }
  };

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Image</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Images</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Images</h5>
                    </Col>
                    <Col md={2}>
                      {/* <Link className="btn btn-primary" to="/image/create">
                        Add Image
                      </Link> */}
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={images}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Image?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeImage}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : imageUrl ? (
        <Modal show={true} onHide={() => setImageUrl("")}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={`${Config.filePath}${imageUrl}`} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setImageUrl("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : confirmApprove.id ? (
        <Modal
          show={true}
          onHide={() => setconfirmApprove({ id: "", isApproved: null })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Approve Iamge</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to approve this Image?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setconfirmApprove({ id: "", isApproved: null })}
            >
              Close
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={() => {
                updateStatus(confirmApprove.id, confirmApprove.isApproved);
              }}
            >
              Approve
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default ImageList;
