import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getReportListAsync, ReportState } from "../ReportSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { ReportDetailInterface } from "../models/ReportDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteReport, deleteReportWithImage } from "../ReportAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Config from "../../../utils/config";
import AxiosInstance from "../../../utils/AxiosInstance";
import { CKEditor } from "ckeditor4-react";
import { Formik } from "formik";

/**
 * Reporting list page
 */
const ReportList = () => {
  const { reports, status, totalRecord } = useAppSelector(ReportState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const [deleteItem, setDeleteItem] = useState("");
  const [sendMail, setSendMail] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getReportListAsync(filter));
  };

  const removeReport = async () => {
    const res = await deleteReport(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const removeReportWithImage = async () => {
    const res = await deleteReportWithImage(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "file_id",
      text: "Thumbnail",
      classes: "col-1",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="">
          <img
            className="thumbnail"
            src={Config?.filePath + row?.docs[0]?.file_path}
            alt={row?.docs[0]?.file_path}
          />
        </div>
      ),
    },
    {
      dataField: "",
      text: "Owner",
      formatter: (cellContent: any, row: any) => row?.docs[0]?.user[0]?.username,
    },
    {
      dataField: "reportedBy",
      text: "Reported By",
      formatter: (cellContent: any, row: any) => row?.user[0]?.username,
    },
    {
      dataField: "actions",
      text: "Actions",
      classes: "col-2",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-trash-2 text-danger fw-bold"
            onClick={() => {
              setDeleteItem(row?._id);
            }}
          ></i>
          <i
            onClick={() => {
              setImageUrl(row?.docs[0]?.file_path);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
          <i
            onClick={() => {
              setSendMail(row?.docs[0]?.user[0]?.email);
            }}
            className="feather icon-mail text-secondary fw-bold"
          ></i>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  const onFormSubmit = async (values: any) => {
    const data = {
      email: values.mailTo,
      subject: values.subject,
      content: values.content,
    };
    const res = await AxiosInstance.post("email/send", data);
    if (res.status === 200) {
      setSendMail("");
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Report</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Reports</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Reports</h5>
                    </Col>
                    <Col md={2}>
                      {/* <Link className="btn btn-primary" to="/report/create">
                        Add Report
                      </Link> */}
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={reports}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>

                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Report?</Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button> */}
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeReport}
            >
              Delete Report
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeReportWithImage}
            >
              Remove Image From Profile
            </Button>
          </Modal.Footer>
        </Modal>
      ) : sendMail ? (
        <Modal size={"lg"} show={true} onHide={() => setSendMail("")}>
          <Modal.Header closeButton>
            <Modal.Title>Send Mail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  subject: "",
                  content: "",
                  mailTo: sendMail,
                }}
                validate={(values: {
                  subject: string;
                  content: string;
                  mailTo: string;
                }) => {
                  const errors: {
                    subject?: string;
                    content?: string;
                    mailTo?: string;
                  } = {};

                  if (!values.mailTo) {
                    errors.mailTo = "Required";
                  }
                  if (!values.subject) {
                    errors.subject = "Required";
                  }
                  if (!values.content) {
                    errors.content = "Required";
                  }
                  return errors;
                }}
                onSubmit={onFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form id="mail-form" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="subject">Mail To</label>
                      <input
                        className={
                          errors.mailTo && touched.mailTo && errors.mailTo
                            ? "form-control input-error"
                            : "form-control"
                        }
                        type="text"
                        name="mailTo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailTo}
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="subject">Subject</label>
                      <input
                        className={
                          errors.subject && touched.subject && errors.subject
                            ? "form-control input-error"
                            : "form-control"
                        }
                        type="text"
                        name="subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.subject}
                        placeholder="Enter subject"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="content">Content</label>
                      <CKEditor
                        initData=""
                        style={{
                          border:
                            errors.content && touched.content && errors.content
                              ? "2px solid #dc3545"
                              : "",
                        }}
                        name="content"
                        onChange={(e) => {
                          setFieldValue("content", e.editor.getData());
                        }}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSendMail("")}>
              Close
            </Button>
            <Button
              form="mail-form"
              type="submit"
              className="btn-success"
              variant="primary"
            >
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      ) : imageUrl ? (
        <Modal show={true} onHide={() => setImageUrl("")}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-image-preview">
            <img className="image-preview" src={`${Config.filePath}${imageUrl}`} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setImageUrl("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default ReportList;
