import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getStoreListAsync, StoreState } from "../StoreSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { StoreDetailInterface } from "../models/StoreDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteStore } from "../StoreAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Formik } from "formik";
import AxiosInstance from "../../../utils/AxiosInstance";
import Config from "../../../utils/config";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

/**
 * Storeing list page
 */
const StoreList = () => {
  const { stores, status, totalRecord } = useAppSelector(StoreState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const smallFileRef = useRef(null);
  const largeFileRef = useRef(null);

  const [deleteItem, setDeleteItem] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [smallSelectedFile, setSmallSelectedFile] = useState<File | null>(null);
  const [largeSelectedFile, setLargeSelectedFile] = useState<File | null>(null);
  const [smallPreview, setSmallPreview] = useState<any>("");
  const [largePreview, setLargePreview] = useState<any>("");

  useEffect(() => {
    if (smallSelectedFile) {
      setSmallPreview(URL.createObjectURL(smallSelectedFile));
    }
    if (largeSelectedFile) {
      setLargePreview(URL.createObjectURL(largeSelectedFile));
    }
  }, [smallSelectedFile, largeSelectedFile]);

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getStoreListAsync(filter));
  };

  const removeStore = async () => {
    const res = await deleteStore(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "smallfile",
      text: "Small File",
      classes: "col-1",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="">
          <img
            className="thumbnail"
            src={Config.filePath + "store/" + row.smallfile}
            alt={row.smallfile}
          />
        </div>
      ),
    },
    {
      dataField: "largefile",
      text: "Large File",
      classes: "col-1",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="">
          <img
            className="thumbnail"
            src={Config.filePath + "store/" + row.largefile}
            alt={row.largefile}
          />
        </div>
      ),
    },
    {
      dataField: "filename",
      text: "File name",
    },
    {
      dataField: "category",
      text: "Category",
    },

    {
      dataField: "actions",
      text: "Actions",
      classes: "col-2",
      headerAlign: "center",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-trash-2 text-danger fw-bold"
            onClick={() => {
              setDeleteItem(row._id);
            }}
          ></i>
         <i
            onClick={() => {
              setImageUrl(row.smallfile);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
          <i
            onClick={() => {
              setImageUrl(row.largefile);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  // Image Modal
  const onImageFormSubmit = async (values: any) => {

    const formData = new FormData();
    formData.append("filename", values.fileName);
    formData.append("smallfile", smallSelectedFile);
    formData.append("largefile", largeSelectedFile);
    formData.append("category", values.fileCategory);

    // const res = await AxiosInstance.post("storeimages/upload", formData);
    const res = await AxiosInstance.post("storefiles/upload", formData);
    if (res.data.status) {
      setOpenImageModal(false);
      toast.success("Image Uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getPageData();
    }
  };

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Store</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Stores</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Stores</h5>
                    </Col>
                    <Col md={2}>
                      {/* <Link className="btn btn-primary" to="/storeimage/create">
                        Add Store
                      </Link> */}
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={stores}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />

                              <Button
                                className="btn-warning fw-bold ms-2"
                                onClick={() => {
                                  setOpenImageModal(true);
                                }}
                              >
                                Upload Image
                              </Button>
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>

                      {/* <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Store?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeStore}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : openImageModal ? (
        <Modal size={"lg"} show={true} onHide={() => setOpenImageModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Modal.Body>
                  <div>
                    <Formik
                      initialValues={{
                        fileName: "",
                        smallFile: "",
                        largeFile: "",
                        fileCategory: "",
                      }}
                      validate={(values: {
                        fileName: string;
                        smallFile: string;
                        largeFile: string;
                        fileCategory: string;
                      }) => {
                        const errors: {
                          fileName?: string;
                          smallFile?: string;
                          largeFile?: string;
                          fileCategory?: string;
                        } = {};

                        if (!values.fileName) {
                          errors.fileName = "Required";
                        }
                        if (!values.smallFile) {
                          errors.smallFile = "Required";
                        }
                        if (!values.largeFile) {
                          errors.largeFile = "Required";
                        }
                        if (!values.fileCategory) {
                          errors.fileCategory = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={onImageFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form id="image-upload-form" onSubmit={handleSubmit}>
                          <div className="form-group mb-3">
                            <label htmlFor="fileName">File Name</label>
                            <input
                              className={
                                errors.fileName &&
                                touched.fileName &&
                                errors.fileName
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              type="text"
                              name="fileName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.fileName}
                              placeholder="Enter file name"
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="file">Small File</label>
                            <input
                              ref={smallFileRef}
                              className={
                                errors.smallFile &&
                                touched.smallFile &&
                                errors.smallFile
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              name="smallFile"
                              onChange={(e) => {
                                handleChange(e);
                                setSmallSelectedFile(e.target.files[0]);
                              }}
                              onBlur={handleBlur}
                              value={values.smallFile}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="largeFile">Large File</label>
                            <input
                              ref={largeFileRef}
                              className={
                                errors.largeFile &&
                                touched.largeFile &&
                                errors.largeFile
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              name="largeFile"
                              onChange={(e) => {
                                handleChange(e);
                                setLargeSelectedFile(e.target.files[0]);
                              }}
                              onBlur={handleBlur}
                              value={values.largeFile}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="fileCategory">File Category</label>
                            <select
                              name="fileCategory"
                              value={values.fileCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.fileCategory &&
                                touched.fileCategory &&
                                errors.fileCategory
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                            >
                              <option value="" label="Select file category">
                                {" "}
                                Select file category{" "}
                              </option>
                              <option value="citylife" label="Citylife">
                                {" "}
                                Citylife{" "}
                              </option>
                              <option value="flags" label="Flags">
                                {" "}
                                Flags{" "}
                              </option>
                              <option value="freeitems" label="Free Items">
                                {" "}
                                Free items{" "}
                              </option>
                              <option value="nature" label="Nature">
                                {" "}
                                Nature{" "}
                              </option>
                            </select>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </Modal.Body>
              </Col>
              <Col md={3}>
                {smallPreview ? (
                  <div className="upload-image-preview">
                    <img
                      className="image-preview"
                      src={smallPreview}
                      alt="smallPreview"
                    />
                  </div>
                ) : null}
                </Col>
                <Col md={3}>

                {largePreview ? (
                  <div className="upload-image-preview">
                    <img
                      className="image-preview"
                      src={largePreview}
                      alt="largePreview"
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setOpenImageModal(false)}
            >
              Close
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              type="submit"
              form="image-upload-form"
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      ) : imageUrl ? (
        <Modal show={true} onHide={() => setImageUrl("")}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-image-preview">
              <img
                className="image-preview"
                src={`${Config.filePath}/store/${imageUrl}`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setImageUrl("")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default StoreList;
